import React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { OperationTypeListingPageQuery } from '../../gatsby-graphql';
import PropertiesListingDiscovery from '../components/PropertiesListingDiscovery/PropertiesListingDiscovery';

interface ILocationTypeListingTemplateProps extends PageRendererProps {
  data: OperationTypeListingPageQuery;
  pageContext: {
    operationTypeSlug: string;
    propertyTypeSlug: string;
    propertyTypeName: string;
    operationTypeListingName: string;
    propertyLocationName: string;
    propertyLocationSlug: string;
  };
}

const LocationTypeListingTemplate: React.FC<ILocationTypeListingTemplateProps> = ({
  data: {
    navigation,
    footer,
    properties,
    propertyLocations,
    propertiesListingConfig,
    propertySpecs,
  },
  pageContext: {
    operationTypeListingName,
    propertyTypeName,
    propertyTypeSlug,
    propertyLocationName,
    propertyLocationSlug,
  },
}) => (
  <Layout navigation={navigation} footer={footer}>
    <PropertiesListingDiscovery
      {...{
        propertyLocations,
        propertyTypeName,
        operationTypeListingName,
        propertyLocationName,
      }}
      propertiesListingConfig={propertiesListingConfig.nodes[0]}
      properties={properties.nodes}
      propertySpecs={propertySpecs.nodes}
      totalProperties={properties.totalCount}
      activePropertyType={{ label: propertyTypeName, value: propertyTypeSlug }}
      activePropertyLocationType={{
        label: propertyLocationName,
        value: propertyLocationSlug,
      }}
    />
  </Layout>
);

export const pageQuery = graphql`
  query LocationTypeListingPage(
    $operationTypeId: String!
    $propertyTypeId: String!
    $propertyLocationId: String!
  ) {
    ...SiteNavigation
    ...PageListingFilters
    properties: allContentfulProperty(
      sort: { fields: [createdAt] }
      filter: {
        operationType: { id: { eq: $operationTypeId } }
        propertyType: { id: { eq: $propertyTypeId } }
        propertyLocation: { id: { eq: $propertyLocationId } }
      }
    ) {
      totalCount
      nodes {
        ...PropertyCard
      }
    }
  }
`;

export default LocationTypeListingTemplate;
